import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | In-blase Food Delivery
			</title>
			<meta name={"description"} content={"Where convenience meets culinary delight. We specialize in delivering a wide variety of delicious meals right to your door. "} />
			<meta property={"og:title"} content={"Home | In-blase Food Delivery"} />
			<meta property={"og:description"} content={"Where convenience meets culinary delight. We specialize in delivering a wide variety of delicious meals right to your door."} />
			<meta property={"og:image"} content={"https://in-blasetump.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://in-blasetump.com/img/6425534.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://in-blasetump.com/img/6425534.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://in-blasetump.com/img/6425534.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://in-blasetump.com/img/6425534.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://in-blasetump.com/img/6425534.png"} />
			<meta name={"msapplication-TileImage"} content={"https://in-blasetump.com/img/6425534.png"} />
			<meta name={"msapplication-TileColor"} content={"https://in-blasetump.com/img/6425534.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#181736" quarkly-title="Hero-20">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-x="hidden"
				lg-overflow-y="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans" color="--light">
						In-blase Food Delivery
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#d5dae2">
					Where convenience meets culinary delight. We specialize in delivering a wide variety of delicious meals right to your door. From local favorites to global cuisines, our service ensures you can enjoy a diverse range of flavors without stepping out of your home.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-secondary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-color="--color-secondary"
							border-width="2px"
							border-style="solid"
							hover-background="rgba(139, 118, 253, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							sm-align-self="center"
						>
							Contact Us
						</Button>
					</Box>
				</Box>
				<Image
					src="https://in-blasetump.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					lg-margin="0px 0px 0px 50px"
					sm-margin="0px 0px 0px 15px"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Why Us?
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://in-blasetump.com/img/2.jpg"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Diverse Culinary Selections – Our platform offers an array of dining options from multiple restaurants, catering to all taste preferences and dietary needs.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://in-blasetump.com/img/3.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Quick and Reliable Delivery – We pride ourselves on our swift delivery service, ensuring your food arrives hot and fresh, every time.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://in-blasetump.com/img/4.jpg"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					User-Friendly Ordering System – Our easy-to-navigate website makes ordering your next meal a breeze.
				</Text>
			</Box>
		</Section>
		<Components.Services />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});